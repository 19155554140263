<template>
  <div class="basic-info">
    <BaseHeaderBar
      title="Infomasi Dasar"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <form class="form-content" @submit.prevent="onSubmit">
      <input
        v-model="name"
        type="text"
        maxlength="30"
        placeholder="Silakan masukkan nama"
        @copy.prevent
        @cut.prevent
        @paste.prevent
      />
      <input
        v-model.trim="ktp"
        type="tel"
        minlength="16"
        maxlength="20"
        placeholder="Silakan masukkan NIK KTP"
        @copy.prevent
        @cut.prevent
        @paste.prevent
      />
      <input
        v-model.trim="whatsApp"
        type="tel"
        minlength="9"
        maxlength="13"
        placeholder="Silakan masukkan nomor Whatsapp"
        @copy.prevent
        @cut.prevent
        @paste.prevent
      />
      <input
        v-model.trim="mail"
        type="text"
        placeholder="Silakan masukkan email"
        @copy.prevent
        @cut.prevent
        @paste.prevent
      />
      <div class="select-block">
        <input
          type="text"
          readonly
          :value="education"
          placeholder="Silakan pilih pendidikan"
          @click="select('education')"
          @copy.prevent
          @cut.prevent
          @paste.prevent
        />
        <img src="@/assets/img/arrow-right.png" class="arrow-down" />
      </div>
      <div class="select-block">
        <input
          type="text"
          readonly
          :value="marriage"
          placeholder="Silakan pilih status pernikahan"
          @click="select('marriage')"
          @copy.prevent
          @cut.prevent
          @paste.prevent
        />
        <img src="@/assets/img/arrow-right.png" class="arrow-down" />
      </div>
      <div class="select-block">
        <input
          type="text"
          readonly
          :value="incomeSource"
          placeholder="Silakan pilih sumber penghasilan"
          @click="select('incomeSource')"
          @copy.prevent
          @cut.prevent
          @paste.prevent
        />
        <img src="@/assets/img/arrow-right.png" class="arrow-down" />
      </div>
      <div class="select-block">
        <input
          type="text"
          readonly
          :value="residence"
          placeholder="Silakan pilih tipe rumahk"
          @click="select('residence')"
          @copy.prevent
          @cut.prevent
          @paste.prevent
        />
        <img src="@/assets/img/arrow-right.png" class="arrow-down" />
      </div>
      <div class="select-block">
        <input
          type="text"
          readonly
          :value="address"
          placeholder="Silakan pilih alamat tempat tinggal"
          @click="addressVisible = true"
          @copy.prevent
          @cut.prevent
          @paste.prevent
        />
        <img src="@/assets/img/arrow-right.png" class="arrow-down" />
      </div>
      <input
        v-model.trim="addressDetail"
        type="text"
        maxlength="30"
        placeholder="Silakan masukkan alamat lengkap tempat tinggal"
        @copy.prevent
        @cut.prevent
        @paste.prevent
      />
      <div class="select-block">
        <input
          type="text"
          readonly
          :value="loanUse"
          placeholder="Silakan pilih tujuan pinjaman"
          @click="select('loanUse')"
          @copy.prevent
          @cut.prevent
          @paste.prevent
        />
        <img src="@/assets/img/arrow-right.png" class="arrow-down" />
      </div>
      <div class="select-block">
        <input
          type="text"
          readonly
          :value="annualIncome"
          placeholder="Silakan pilih pemasukan tahunan"
          @click="select('annualIncome')"
          @copy.prevent
          @cut.prevent
          @paste.prevent
        />
        <img src="@/assets/img/arrow-right.png" class="arrow-down" />
      </div>
      <div class="select-block">
        <input
          type="text"
          readonly
          :value="profession"
          placeholder="Silakan pilih jenis pekerjaan"
          @click="select('profession')"
          @copy.prevent
          @cut.prevent
          @paste.prevent
        />
        <img src="@/assets/img/arrow-right.png" class="arrow-down" />
      </div>
      <div class="select-block">
        <input
          type="text"
          readonly
          :value="industry"
          placeholder="Silakan pilih industri pekerjaan"
          @click="select('industry')"
          @copy.prevent
          @cut.prevent
          @paste.prevent
        />
        <img src="@/assets/img/arrow-right.png" class="arrow-down" />
      </div>
      <input
        v-if="profession != 'Murid' && profession != 'Tidak bekerja'"
        v-model.trim="codrmpanyName"
        type="text"
        maxlength="30"
        placeholder="Silakan masukkan nama toko atau perusahaan"
        @copy.prevent
        @cut.prevent
        @paste.prevent
      />
      <input
        v-if="profession != 'Murid' && profession != 'Tidak bekerja'"
        v-model.trim="codrmpanyAddress"
        type="text"
        maxlength="30"
        placeholder="Silakan masukkan alamat lengkap toko atau perusahaan"
        @copy.prevent
        @cut.prevent
        @paste.prevent
      />
      <p class="desc">
        Silakan pilih 3 nomor berbeda dari daftar kontak Anda yang dapat
        dihubungi kapan pun, baik kerabat/teman/nomor pribadi lainnya untuk
        ditambahkan sebagai kontak darurat. Kontak darurat hanya digunakan untuk
        keperluan evaluasi.
      </p>
      <div v-for="n in 3" :key="n">
        <div class="select-block">
          <input
            type="text"
            readonly
            :value="contact[n - 1].contactRelationship"
            placeholder="Silakan pilih hubungan dengan kontak darurat"
            @click="select('contact', n - 1)"
            @copy.prevent
            @cut.prevent
            @paste.prevent
          />
          <img src="@/assets/img/arrow-right.png" class="arrow-down" />
        </div>
        <input
          v-show="!isSelect || contact[n - 1].contactName"
          v-model.trim="contact[n - 1].contactName"
          type="text"
          placeholder="Silakan Masukkan Nama Kontak Darurat"
          @input="handleInput($event, n - 1)"
          @copy.prevent
          @cut.prevent
          @paste.prevent
          :maxlength="isSelect ? 0 : 99"
          :readonly="isSelect"
        />

        <div class="select-block">
          <input
            v-model.trim="contact[n - 1].contactPhone"
            type="tel"
            maxlength="13"
            placeholder="Masukkan nomor telepon dengan awalan 08"
            @copy.prevent
            @cut.prevent
            @paste.prevent
            @click="toAddressBook(n)"
            :readonly="isSelect"
          />
          <img
            v-if="isSelect"
            src="@/assets/img/arrow-right.png"
            class="arrow-down"
          />
        </div>
      </div>
      <button type="button" class="confirm-btn" @click="onSubmit">Kirim</button>
    </form>
    <van-popup v-model="visible" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns[curSelectedKey]"
        cancel-button-text="Batal"
        confirm-button-text="Yakin"
        @cancel="onCancel"
        @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model="addressVisible" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="Silakan pilih alamat tempat tinggal"
        placeholder="Silakan pilih"
        :options="addressOptions"
        @close="onCloseDialog('addressVisible')"
        @change="onAddressChange"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { Popup, Picker, Cascader, Toast } from 'vant'
import { validatePhoneNumber, validateEmail, validateContactPhone } from '@/utils/validator'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'

Vue.use(Popup)
Vue.use(Picker)
Vue.use(Cascader)
Vue.use(Toast)

let ktpStatus

export default {
  name: 'BasicInfo',
  mixins: [dialogVisible],
  data () {
    return {
      name: '',
      ktp: '',
      whatsApp: '',
      mail: '',
      addressDetail: '',
      codrmpanyName: '',
      codrmpanyAddress: '',
      education: '',
      marriage: '',
      incomeSource: '',
      residence: '',
      loanUse: '',
      annualIncome: '',
      profession: '',
      industry: '',
      contact: [
        {
          contactRelationship: '',
          contactName: '',
          contactPhone: ''
        },
        {
          contactRelationship: '',
          contactName: '',
          contactPhone: ''
        },
        {
          contactRelationship: '',
          contactName: '',
          contactPhone: ''
        }
      ],
      address: '',
      columns: {
        education: [],
        marriage: [],
        incomeSource: [],
        residence: [],
        loanUse: [],
        annualIncome: [],
        profession: [],
        industry: [],
        contact: []
      },
      cascaderValue: '',
      addressOptions: [],
      curSelectedKey: '',
      addressVisible: false,
      isSelect: false
    }
  },
  computed: mapState(['userInfo']),
  created () {
    this.getBasicInfo()
    this.getProvince()
    this.getContantList()
  },
  methods: {
    getContantList () {
      request.post('contantList')
        .then(res => {
          if (res.list.length > 0) {
            this.isSelect = true
          }
        })
    },
    getBasicInfo () {
      request.post('badrsicInfoPage')
        .then(res => {
          this.name = res.cudrstomerName
          this.ktp = res.cudrstomerIdcard
          this.education = res.cudrstomerEducation
          this.marriage = res.cudrstomer_marrystatus
          this.columns = {
            education: res.eddrucationList,
            marriage: res.madrrrystatusList,
            incomeSource: res.indrcomesourceList,
            residence: res.redrsidencetypeList,
            loanUse: res.usdreoffundsList,
            annualIncome: res.yedrarincomeList,
            profession: res.wodrrktypeList,
            industry: res.wodrrkindustryList,
            contact: res.redrlationshipList
          }
          ktpStatus = res.ktdrp_status
        })
    },
    getProvince () {
      request.post('obdrProvince')
        .then(res => {
          this.addressOptions = res.lidrst.map(item => (
            {
              text: item.prdrovincesName,
              value: item.p_id,
              children: []
            }
          ))
          this.getCity(this.addressOptions[0], res.lidrst[0].p_id).then(cityList => {
            this.getCounty(this.addressOptions[0].children[0], cityList[0].cdrID)
          })
        })
    },
    getCity (option, provinceId) {
      const postData = {
        pdrID: provinceId
      }
      return request.post('obdrCity', postData)
        .then(res => {
          option.children = res.lidrst.map(item => (
            {
              text: item.cidrtyName,
              value: item.cdrID,
              children: []
            }
          ))
          return res.lidrst
        }).catch(e => {
          throw e
        })
    },
    getCounty (option, cityId) {
      const postData = {
        cdrID: cityId
      }
      request.post('obdrCounty', postData)
        .then(res => {
          option.children = res.lidrst.map(item => (
            {
              text: item.codruntyName,
              value: item.c_id
            }
          ))
        })
    },
    validateContactPhone (contactPhone) {
      if (!contactPhone) {
        Toast('Masukkan nomor telepon dengan awalan 08')
        return false
      }
      if (!validateContactPhone(contactPhone)) {
        Toast('Format nomor telepon kontak salah')
        return false
      }
      if (contactPhone === this.userInfo.usdrerMobile) {
        Toast('Nomor kontak darurat tidak boleh sama dengan nomor pendaftaran')
        return false
      }
      if (this.contact.filter(item => item.contactPhone === contactPhone).length > 1) {
        Toast('Kedua nomor kontak darurat tidak boleh sama')
        return false
      }
      return true
    },
    validate () {
      if (!this.name) {
        Toast('Silakan masukkan nama')
        return false
      }
      if (!/^[A-Za-z\s]+$/.test(this.name)) {
        Toast('Format nama salah')
        return false
      }
      if (!this.ktp) {
        Toast('Silakan masukkan NIK KTP')
        return false
      }
      if (!/^\d{16,20}$/.test(this.ktp)) {
        Toast('Format NIK salah')
        return false
      }
      if (!this.whatsApp) {
        Toast('Silakan masukkan nomor Whatsapp')
        return false
      }
      if (!validatePhoneNumber(this.whatsApp)) {
        Toast('Format Whatsapp salah')
        return false
      }
      if (!this.mail) {
        Toast('Silakan masukkan email')
        return false
      }
      if (!validateEmail(this.mail)) {
        Toast('Format email salah')
        return false
      }
      if (!this.education) {
        Toast('Silakan pilih pendidikan')
        return false
      }
      if (!this.marriage) {
        Toast('Silakan pilih status pernikahan')
        return false
      }
      if (!this.incomeSource) {
        Toast('Silakan pilih sumber penghasilan')
        return false
      }
      if (!this.residence) {
        Toast('Silakan pilih tipe rumah')
        return false
      }
      if (!this.address) {
        Toast('Silakan pilih alamat tempat tinggal')
        return false
      }
      if (!this.addressDetail) {
        Toast('Silakan masukkan alamat lengkap tempat tinggal')
        return false
      }
      if (!this.loanUse) {
        Toast('Silakan pilih tujuan pinjaman')
        return false
      }
      if (!this.annualIncome) {
        Toast('Silakan pilih pemasukan tahunan')
        return false
      }
      if (!this.profession) {
        Toast('Silakan pilih jenis pekerjaan')
        return false
      }
      if (!this.industry) {
        Toast('Silakan pilih industri pekerjaan')
        return false
      }
      if (this.profession !== 'Murid' && this.profession !== 'Tidak bekerja') {
        if (!this.codrmpanyName) {
          Toast('Silakan masukkan nama toko atau perusahaan')
          return false
        }
        if (!this.codrmpanyAddress) {
          Toast('Silakan masukkan alamat lengkap toko atau perusahaan')
          return false
        }
      }
      this.contact.forEach(item => {
        // 使用正则表达式替换空格、连字符、+62和+字符
        const cleanedStr = item.contactPhone.replace(/[\s+-]|\+62\b/g, '')
        item.contactPhone = cleanedStr.replace(/^628(.*)$/, '08$1')
        if (String(cleanedStr.charAt(0)) === '8') {
          item.contactPhone = '0' + cleanedStr
        }
      })
      for (let i = 0; i < this.contact.length; i++) {
        if (!this.contact[i].contactRelationship) {
          Toast('Silakan pilih hubungan dengan kontak darurat')
          return false
        }
        if (!this.contact[i].contactName) {
          Toast('Silakan Masukkan Nama Kontak Darurat')
          return false
        }
        if (!this.validateContactPhone(this.contact[i].contactPhone)) {
          return false
        }
      }
      return true
    },
    // 限制不能输入除字母以外得字符
    handleInput (event, index) {
      const filteredText = event.target.value.replace(/[^a-zA-Z]/g, '')
      this.contact[index].contactName = filteredText
    },
    select (key, index) {
      this.curSelectedKey = key
      index !== undefined && (this.contactIndex = index)
      this.visible = true
    },
    toAddressBook (index) {
      if (this.isSelect) {
        this.$router.push({
          path: '/addressBook',
          query: {
            number: index - 1,
            contact: JSON.stringify(this.contact)
          }
        })
      }
    },

    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        usdrerName: this.name,
        usdrerIdcard: this.ktp,
        usdrerWhatsapp: this.whatsApp,
        usdrerEmail: this.mail,
        usdrerEducation: this.education,
        usdrerMarrystatus: this.marriage,
        usdrerIncomesource: this.incomeSource,
        usdrerResidencetype: this.residence,
        usdrerResidentialarea: this.address,
        usdrerAddress: this.addressDetail,
        usdrerWorktype: this.profession,
        usdrerWorkindustry: this.industry,
        usdrerYearincome: this.annualIncome,
        usdrerUseoffunds: this.loanUse,
        ktdrpStatus: ktpStatus,
        codrmpanyAddress: this.codrmpanyAddress,
        codrmpanyName: this.codrmpanyName,
        codrntactList: JSON.stringify(this.contact)
      }
      request.post('badrsicInfosubmit', postData)
        .then(() => {
          Toast('Pembaruan infomasi dasar berhasil')
          this.$router.back()
        }).finally(() => {
          this.flag = false
        })
    },
    onCancel () {
      this.onCloseDialog()
      this.curSelectedKey = ''
    },
    onConfirm (value) {
      if (this.curSelectedKey === 'contact') {
        this.contact[this.contactIndex].contactRelationship = value
      } else {
        this[this.curSelectedKey] = value
      }
      this.onCloseDialog()
      this.curSelectedKey = ''
    },
    onAddressChange ({ value, selectedOptions, tabIndex }) {
      if (tabIndex === 0) {
        if (!selectedOptions[0].children.length) {
          this.getCity(selectedOptions[0], value).then(cityList => {
            this.getCounty(selectedOptions[0].children[0], cityList[0].cdrID)
          })
        }
      } else if (tabIndex === 1) {
        if (!selectedOptions[1].children.length) {
          this.getCounty(selectedOptions[1], value)
        }
      }
    },
    onFinish ({ selectedOptions }) {
      this.address = selectedOptions.map(option => option.text).join('/')
      this.onCloseDialog('addressVisible')
    }
  },
  beforeRouteEnter (to, from, next) {
    const { userphone, username, number } = to.query
    if (userphone) {
      next(vm => {
        vm.contact[number].contactPhone = userphone
        vm.contact[number].contactName = username || userphone
      })
      return
    }
    next()
  }
}
</script>

<style scoped lang="less">
.basic-info {
  overflow: auto;
  background: #29b8d6;
}

.form-content {
  margin-top: 24px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 60px;
  padding-bottom: 100px;
}

input {
  display: block;
  width: 100%;
  line-height: 94px;
  box-sizing: border-box;
  color: #fff;
  height: 94px;
  padding-left: 20px;
  font-size: 32px;
  margin-bottom: 40px;
  border: #eee solid 1px;
  background: none;
  border-radius: 10px;
  font-weight: 600;
  &:nth-child(n + 2) {
    margin-top: 16px;
  }

  &::placeholder {
    font-size: 26px;
    color: #ddd;
  }
}

.select-block {
  position: relative;
  margin-top: 16px;

  .arrow-down {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 21px;
    height: 34px;
    transform: translateY(-50%);
    margin-right: 20px;
  }
  input {
    padding-right: 80px;
    white-space: nowrap; /* 禁止文本换行 */
    overflow: hidden; /* 隐藏超出部分的文本 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
  }
}

.desc {
  margin-top: 30px;
  margin-bottom: 39px;
  font-size: 26px;
  font-weight: 500;
  color: #e94d4d;
  line-height: 40px;
}

.confirm-btn {
  .submit-btn;
  margin-top: 48px;
  margin-bottom: 48px;
  font-size: 30px;
  width: 620px;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  background: #1c8aa1;
}
</style>
